

.contact-main{
    min-height: calc(100vh - 84px);
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 84px;
    color: white;
}
.contact-main .minitext{
    font-size: 0.8em;
}
.contact-main .web{
    text-decoration: underline;
}


.contact-main .container .left{
    height: calc(70vh - 2rem);
    padding-bottom: 2rem;
    border-radius: 3rem 3rem 0px 3rem;
    overflow: hidden;
}
.contact-main.mobile .container .left{
    height: 60px;
    border-radius: 0px 0px 3rem 3rem;
}
.contact-main .container .left img.bg{
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
}
.contact-main .container .left p{
    z-index: 1;
    color: black;
}
.contact-main .container .logo{
    max-height: 60px;
    aspect-ratio: 1 / 1;
    z-index: 1;
}
.contact-main .container .left .title{
    font-size: 2rem;
    z-index: 1;
    font-weight: bold;
    color: black;
}

.contact-main .container .left .actions{
    z-index: 99;
}
.contact-main .container .left .social-icon{
    width: 25px;
}
.contact-main .container .left .social-icon.active{
    border-bottom: 0px solid black;
}
.contact-main .container .left .social-icon.fb{
    fill: rgb(31, 106, 171);
}
.contact-main .container .left .social-icon.insta{
    fill: rgb(172, 41, 198);
}
.contact-main .container .left .social-icon.mail{
    fill: rgb(148, 117, 55);
}
.contact-main .container .left .social-icon.phone{
    fill: rgb(59, 114, 37);
}


.contact-main .container .right{
    background-color: #89a3c0;
    border-radius: 3rem 3rem 3rem 0rem;
    
    height: calc(70vh - 2rem);
    position: relative;
    overflow: hidden;
    text-shadow: 1px 1px 3px black;
}
.contact-main.mobile .container .right{
    border-radius: 3rem 3rem 0rem 0rem;
}

.contact-main .container .right img{
    height: 200px;
    transition: all 0.5s ease-in-out;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);
}

.contact-main .container .right img.fb{
    border-radius: 2rem;
}
.contact-main .container .right img.insta{
    border-radius: 50%;
}
.contact-main .container .right img.default{
    /* border-radius: 50%; */
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 0;
}
.contact-main .container .right img.nbrdr{
    border-radius: 0px;
}


.contact-main .container .right .social{
    z-index: 1;
}
.contact-main .container .right .social input{
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);
    border: none;
}
.contact-main .container .right .title{
    font-size: 2rem;
    font-weight: bold;
    z-index: 1;
}
.contact-main .container .right .foot{
    position: absolute;
    bottom: 1rem;
    width: 70%;
}
.contact-main .container .right .foot .step{
    width: calc( (100% / 4) - 1rem );
    height: 10px;
    backdrop-filter: blur(6px);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}
.contact-main .container .right .foot._dark .step{
    background-color: rgba(255, 255, 255, 0.6);
}
.contact-main .container .right .foot._light .step{
    background-color: rgba(0, 0, 0, 0.6);
}

.contact-main .container .right .foot .step.active{
    backdrop-filter: blur(6px);
    width:  calc( (100% / 4) + 1rem );
}
.contact-main .container .right .foot._dark .step.active{
    background-color: rgba(25, 27, 34, 0.6);
}
.contact-main .container .right .foot._light .step.active{
    background-color: rgba(170, 184, 236, 0.6);
}


.contact-main .container .right textarea{
    height: 90px;
    resize: none;
    font-size: 0.9rem;
    font-family: Poppins;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    backdrop-filter: blur(6px);
    /* color: black; */
}
.contact-main.mobile .container .right textarea{
    height: 40px;
}

.contact-main .container .right .vcard{
    border-radius: 1rem;
    z-index: 1;
    text-shadow: none;
    background-color: rgba(245, 245, 245, 0.2);
    backdrop-filter: blur(6px);
}
.contact-main .container .right .vcard img{
    height: 150px;
}