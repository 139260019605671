.manager-main{
    position: relative;
    min-height: calc( (100vh - 84px) - 1.75rem );
}




























