.divider .line{
    width: 45%;
    height: 1px;
    background-color: grey;
}

.divider .circle{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: grey;
}

.login-main{
    min-height: calc( (100vh - 84px) - 6rem );
}